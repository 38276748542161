import React from "react"
import { rr } from "../lib/reverse-router"
import Heading from "./Heading"
import Link from "./Link"
import { array_shuffle } from "../lib/array"
import * as styles from "./RelatedPost.module.scss"

interface Props {
  relatedPosts: any
}

const RelatedPost: React.FC<Props> = ({ relatedPosts }) => {
  return (
    <section className={styles.relatedPosts}>
      <Heading level={2}>関連記事</Heading>
      {array_shuffle(relatedPosts.edges)
        .slice(0, 5)
        .map(({ node }) => {
          const { slug, title, tags } = node
          return (
            <article key={node.slug}>
              <Link to={rr.post.post(slug)}>
                <Heading level={3} appearance={4}>
                  <span className={styles.linkText}>{title}</span>
                </Heading>
                {tags && (
                  <ul className={styles.tags}>
                    {tags.map((tag, i) => (
                      <li key={i}>#{tag}</li>
                    ))}
                  </ul>
                )}
              </Link>
            </article>
          )
        })}
    </section>
  )
}

export default RelatedPost
