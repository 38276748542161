import dayjs from "dayjs"

export const absoluteUrl = (path: string): string => {
  return `https://satoshimurata.com${path}`
}

export const stripTagsAndNewLines = (html: string): string => {
  return html.replace(/<[^>]+>/g, "").replace(/\r?\n/g, "")
}

export const truncateText = (text: string, n: number): string => {
  if ([...text].length <= n) {
    return text
  } else {
    return [...text].splice(0, n - 1).join("") + "…"
  }
}

export const formatDate = (datetime: string): string => {
  return dayjs(datetime).format("YYYY年M月D日")
}

export const formatDateTime = (datetime: string): string => {
  return dayjs(datetime).format("YYYY年M月D日 H時m分")
}

export const formatDateYear = (datetime: string): string => {
  return dayjs(datetime).format("YYYY")
}

export const capitalize = (str: string): string => {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
}
