// extracted by mini-css-extract-plugin
export var author = "PostEssentials-module--author--HLczW";
export var content = "PostEssentials-module--content--kpSKn";
export var figure = "PostEssentials-module--figure--YIRhd";
export var header = "PostEssentials-module--header--veeVC";
export var icon = "PostEssentials-module--icon--p5OfL";
export var lead = "PostEssentials-module--lead--YyH5o";
export var main = "PostEssentials-module--main--6Bd2E";
export var meta = "PostEssentials-module--meta--e1HkW";
export var profile = "PostEssentials-module--profile--7UcyR";
export var shareButtons = "PostEssentials-module--shareButtons--RVOBI";