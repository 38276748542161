import React from "react"

export const IconMenu = () => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="メニューを開く"
  >
    <g fill="#fff" stroke="#000" strokeMiterlimit="10">
      <path d="m.77736 5.83001h18.44528" />
      <path d="m.77736 14.16999h18.44528" />
    </g>
  </svg>
)

export const IconClose = () => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="メニューを閉じる"
  >
    <g fill="#fff" stroke="#000" strokeMiterlimit="10">
      <path d="m.77736.77736 18.44528 18.44528" />
      <path d="m.77736 19.22264 18.44528-18.44528" />
    </g>
  </svg>
)

export const IconExternalLink = () => (
  <svg
    height="10"
    viewBox="0 0 10 10"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m3.66.625h5.715v5.722"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.25"
    />
    <path
      d="m.884 9.999-.884-.883 7.666-7.676 1.086-.887.685.683-.933 1.133z"
      fill="currentColor"
    />
  </svg>
)

export const IconArrow = () => (
  <svg
    height="10"
    viewBox="0 0 14 10"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.25"
    >
      <path d="m8.593.959 4.041 4.041-4.047 4.046" />
      <path d="m.00043 5.00062 12.10258-.00842" />
    </g>
  </svg>
)

export const IconChevron = () => (
  <svg
    height="10"
    viewBox="0 0 6 10"
    width="6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m.761.959 4.041 4.041-4.046 4.046"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.25"
    />
  </svg>
)

export const Logo = () => (
  <svg
    viewBox="0 0 70 70"
    width="70"
    height="70"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2">
      <circle cx="35.00002" cy="35" r="34.00001" />
      <path d="m1 35c.00157.251 68 0 68 0" />
      <path d="m35.00002 1v68" />
      <path d="m9.02594 13.00944c1.38258.908 2.23831 2.78656 2.23831 5.48116v3.29118a8.211 8.211 0 1 0 16.42193 0v-9.02967c0-2.6946.85572-4.57314 2.2383-5.48116 1.47465-.96848 3.5274-.87465 5.07552-.87465" />
      <path d="m9.02594 57.10211c1.38258-.908 2.23831-2.78656 2.23831-5.48116v-3.29119a8.211 8.211 0 0 1 16.42193 0v9.02967c0 2.6946.85572 4.57314 2.2383 5.48116 1.47465.96848 3.5274.87466 5.07554.87466" />
      <path d="m19.47521 4.74345v60.73037" />
      <path d="m35 11.83793h11.622a8.211 8.211 0 0 1 0 16.42192h-11.622" />
      <path d="m54.83298 20.04889h-19.83296" />
      <path d="m54.833 20.04889c2.5657 0 4.23553 0 5.423 1.993 3.64 6.10937-14.0287 17.1826-14.0287 44.43673" />
      <path d="m35.00002 50.59346h13.41495" />
    </g>
  </svg>
)

export const IconLink = () => (
  <svg
    height="12"
    viewBox="0 0 20 12"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" strokeMiterlimit="10" strokeWidth="1.5">
      <path
        d="m10.09087 1.76372h2.18181a4.672 4.672 0 0 1 4.72726 4.6147 4.672 4.672 0 0 1 -4.72726 4.6147h-2.18181"
        stroke="currentColor"
      />
      <path
        d="m7.90906 10.99312h-2.18181a4.672 4.672 0 0 1 -4.72725-4.6147 4.672 4.672 0 0 1 4.72725-4.6147h2.18181"
        stroke="currentColor"
      />
      <path d="m5.25972 6.37842h7.09954" stroke="currentColor" />
    </g>
  </svg>
)

export const IconContact = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
  >
    <rect
      x="40"
      y="189.8"
      width="920"
      height="620.39"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="80"
    />
    <polyline
      points="960 293.71 500 603.4 40 293.71"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="80"
    />
  </svg>
)

export const IconFeed = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
  >
    <path d="M286.78,835.72A121.74,121.74,0,1,1,165,714,121.75,121.75,0,0,1,286.78,835.72Z" />
    <path d="M43.45,528.82c114.08,0,221.28,44.59,301.87,125.56s125.15,189,125.15,303.93H646.22c0-333.73-270.4-605.25-602.77-605.25Z" />
    <path d="M43.71,217.25c406.51,0,737.23,332.53,737.23,741.25H956.69c0-505.64-409.56-917-913-917Z" />
  </svg>
)

export const IconGithub = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
  >
    <path
      d="M500,12.35c-276.09,0-500,223.85-500,500C0,733.26,143.25,920.67,342,986.79c25,4.59,34.12-10.85,34.12-24.1,0-11.87-.43-43.31-.67-85-139.09,30.22-168.43-67-168.43-67-22.74-57.76-55.52-73.14-55.52-73.14-45.4-31,3.44-30.39,3.44-30.39,50.17,3.53,76.58,51.52,76.58,51.52C276.08,835,348.51,813,377,800.17c4.54-32.31,17.46-54.35,31.74-66.85C297.71,720.71,181,677.81,181,486.21,181,431.62,200.47,387,232.46,352c-5.15-12.65-22.3-63.49,4.9-132.32,0,0,42-13.45,137.49,51.25,39.89-11.09,82.66-16.63,125.18-16.83,42.46.2,85.23,5.74,125.17,16.83,95.46-64.7,137.37-51.25,137.37-51.25,27.27,68.83,10.11,119.67,5,132.32,32.05,34.95,51.4,79.58,51.4,134.17,0,192.08-116.91,234.35-228.29,246.72,18,15.44,33.94,46,33.94,92.6,0,66.84-.61,120.77-.61,137.16,0,13.37,9,28.93,34.38,24C856.87,920.49,1000,733.2,1000,512.38,1000,236.2,776.11,12.35,500,12.35Z"
      fill="#191313"
      fillRule="evenodd"
    />
  </svg>
)

export const IconHatenablog = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
  >
    <path
      d="M500,960C246.59,960,40,753.41,40,500S246.59,40,500,40,960,246.59,960,500C959.08,753.41,753.41,960,500,960Z"
      fill="none"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="80"
    />
    <path d="M557.88,252.5c-24-49.91-38.92-97.81-46.9-125.75V481c12,5,21,16,21,29.94a32.94,32.94,0,0,1-65.87,0A32.34,32.34,0,0,1,489,480V126.75c-8,27.94-21.95,75.84-46.9,125.75-37.93,77.84-85.83,145.7-85.83,145.7l30.94,417.17S416.17,847.31,500,847.31s112.77-31.94,112.77-31.94L643.71,398.2S595.81,330.34,557.88,252.5Z" />
  </svg>
)

export const IconHome = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
  >
    <path
      d="M198.63,375.83V924.5H401.34v-264H598.66v264H801.37V375.83"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="80"
    />
    <polyline
      points="960.79 536.29 500 75.5 39.21 536.29"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="80"
    />
  </svg>
)

export const IconInstagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
  >
    <path d="M500,90.09c133.51,0,149.32.51,202,2.91,48.75,2.23,75.23,10.37,92.85,17.22,23.33,9.07,40,19.91,57.49,37.4s28.33,34.16,37.4,57.49c6.85,17.62,15,44.1,17.22,92.85,2.4,52.72,2.91,68.53,2.91,202S909.4,649.32,907,702c-2.23,48.75-10.37,75.23-17.22,92.85-9.07,23.33-19.91,40-37.4,57.49s-34.16,28.33-57.49,37.4c-17.62,6.85-44.1,15-92.85,17.22-52.71,2.4-68.52,2.91-202,2.91S350.67,909.4,298,907c-48.75-2.23-75.23-10.37-92.85-17.22-23.33-9.07-40-19.91-57.49-37.4s-28.33-34.16-37.4-57.49c-6.85-17.62-15-44.1-17.22-92.85-2.4-52.72-2.91-68.53-2.91-202S90.6,350.68,93,298c2.23-48.75,10.37-75.23,17.22-92.85,9.07-23.33,19.91-40,37.4-57.49s34.16-28.33,57.49-37.4c17.62-6.85,44.1-15,92.85-17.22,52.72-2.4,68.53-2.91,202-2.91M500,0C364.21,0,347.18.58,293.85,3S204.29,13.89,172.48,26.25C139.6,39,111.72,56.12,83.92,83.92S39,139.6,26.25,172.48C13.89,204.29,5.44,240.63,3,293.85S0,364.21,0,500,.58,652.82,3,706.15s10.88,89.56,23.24,121.37C39,860.4,56.12,888.28,83.92,916.08S139.6,961,172.48,973.75c31.81,12.36,68.15,20.81,121.37,23.24s70.36,3,206.15,3,152.82-.57,206.15-3,89.56-10.88,121.37-23.24c32.88-12.78,60.76-29.87,88.56-57.67S961,860.4,973.75,827.52c12.36-31.81,20.81-68.15,23.24-121.37s3-70.36,3-206.15-.58-152.82-3-206.15-10.88-89.56-23.24-121.37C961,139.6,943.88,111.72,916.08,83.92S860.4,39,827.52,26.25C795.71,13.89,759.37,5.44,706.15,3S635.79,0,500,0Z" />
    <path d="M500,243.24c-141.8,0-256.76,115-256.76,256.76S358.2,756.76,500,756.76,756.76,641.8,756.76,500,641.8,243.24,500,243.24Zm0,423.43A166.67,166.67,0,1,1,666.67,500,166.67,166.67,0,0,1,500,666.67Z" />
    <circle cx="766.9" cy="233.1" r="60" />
  </svg>
)

export const IconProfile = () => (
  <svg
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m0 0h512v512h-512z" fill="#e5e4d2" />
    <path d="m0 0h512v512h-512z" fill="#e5e4d2" />
    <path
      d="m18.94 187.9s101-79.65 238.85-60.67c76.84 10.58 120.83 101.38 144.35 198.88l21.8 90.66 69.12-108-90.62 17.39c-108.89 13.09-275.72-11.02-383.5-138.26z"
      fill="#fff"
    />
    <path
      d="m222.72 124.42-79.36 159.23a457.41 457.41 0 0 0 192 45.05l42.5-79.87c-52.74-123.9-107.01-122.37-155.14-124.41z"
      fill="#a6d5d2"
    />
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    >
      <path d="m377.6 248.58-42.5 80.38" />
      <path d="m18.94 187.9s70.4 5.89 92.93 13.06" />
      <circle cx="113.95" cy="164.07" r="19.74" />
      <path d="m216.06 197.12 18.95 32.26" />
      <path d="m248.83 204.29-50.69 19.97" />
      <path d="m246.37 267.34 17.7 30.13" />
      <path d="m276.99 274.03-47.36 18.66" />
      <path d="m244.33 149.58 17.69 30.13" />
      <path d="m274.94 156.27-47.36 18.66" />
      <path d="m190.98 248.32 18.94 32.26" />
      <path d="m223.74 255.49-50.68 19.97" />
      <path d="m270.14 223.52 14.69 24.76" />
      <path d="m295.39 228.93-38.95 15.53" />
      <path d="m296.26 183.58 14.68 24.77" />
      <path d="m321.5 188.99-38.95 15.54" />
      <path d="m301.89 280.35 14.68 24.76" />
      <path d="m327.13 285.76-38.95 15.53" />
      <path d="m322.71 235.89 15.03 24.55" />
      <path d="m348.03 240.94-38.73 16.08" />
      <path d="m438.27 348.16 20.99 13.31" />
      <path d="m444.42 382.98-16.9-20.48" />
    </g>
    <circle cx="107.62" cy="168.67" r="8.23" />
    <circle cx="158.01" cy="156.65" r="8.23" />
    <circle
      cx="164.85"
      cy="152.55"
      fill="none"
      r="19.74"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m143.62 283.9 79.36-159.23"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m18.94 187.9s101-79.65 238.85-60.67c76.84 10.58 120.83 101.38 144.35 198.88l21.8 90.66 69.12-108-90.62 17.39c-108.89 13.09-275.72-11.02-383.5-138.26z"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m473.6 337.41-25.09-3.59"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m18.94 187.9s101-79.65 238.85-60.67c76.84 10.58 120.83 101.38 144.35 198.88l21.8 90.66 69.12-108-90.62 17.39c-108.89 13.09-275.72-11.02-383.5-138.26z"
      fill="#fff"
    />
    <path
      d="m222.72 124.42-79.36 159.23a457.41 457.41 0 0 0 192 45.05l42.5-79.87c-52.74-123.9-107.01-122.37-155.14-124.41z"
      fill="#a6d5d2"
    />
    <path
      d="m377.6 248.58-42.5 80.38"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m18.94 187.9s70.4 5.89 92.93 13.06"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <circle
      cx="113.95"
      cy="164.07"
      fill="none"
      r="19.74"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m216.06 197.12 18.95 32.26"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m248.83 204.29-50.69 19.97"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m246.37 267.34 17.7 30.13"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m276.99 274.03-47.36 18.66"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m244.33 149.58 17.69 30.13"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m274.94 156.27-47.36 18.66"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m190.98 248.32 18.94 32.26"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m223.74 255.49-50.68 19.97"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m270.14 223.52 14.69 24.76"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m295.39 228.93-38.95 15.53"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m296.26 183.58 14.68 24.77"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m321.5 188.99-38.95 15.54"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m301.89 280.35 14.68 24.76"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m327.13 285.76-38.95 15.53"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m322.71 235.89 15.03 24.55"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m348.03 240.94-38.73 16.08"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m438.27 348.16 20.99 13.31"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m444.42 382.98-16.9-20.48"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <circle cx="107.62" cy="168.67" r="8.23" />
    <circle cx="158.01" cy="156.65" r="8.23" />
    <circle
      cx="164.85"
      cy="152.55"
      fill="none"
      r="19.74"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m143.62 283.9 79.36-159.23"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m18.94 187.9s101-79.65 238.85-60.67c76.84 10.58 120.83 101.38 144.35 198.88l21.8 90.66 69.12-108-90.62 17.39c-108.89 13.09-275.72-11.02-383.5-138.26z"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
    <path
      d="m473.6 337.41-25.09-3.59"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="7.68"
    />
  </svg>
)

export const IconTwitter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
  >
    <path d="M314.49,906.36c377.38,0,583.75-312.65,583.75-583.75,0-8.88-.18-17.72-.59-26.52A417.36,417.36,0,0,0,1000,189.86a409.41,409.41,0,0,1-117.82,32.3,205.89,205.89,0,0,0,90.21-113.49,411.33,411.33,0,0,1-130.27,49.8A205,205,0,0,0,692.36,93.64c-113.31,0-205.19,91.89-205.19,205.16a204.71,204.71,0,0,0,5.32,46.77C322,337,170.78,255.36,69.6,131.22a205.31,205.31,0,0,0,63.51,273.89,203.6,203.6,0,0,1-92.92-25.66c0,.86,0,1.71,0,2.61,0,99.38,70.73,182.33,164.6,201.14a204.83,204.83,0,0,1-92.66,3.52,205.41,205.41,0,0,0,191.67,142.5A411.71,411.71,0,0,1,48.94,817,418.61,418.61,0,0,1,0,814.2a580.82,580.82,0,0,0,314.49,92.16" />
  </svg>
)

export const IconFacebook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
  >
    <path d="M50,0a50.00348,50.00348,0,0,0-7.8125,99.39233V64.45312H29.49219V50H42.1875V38.98438c0-12.53126,7.46466-19.45313,18.88562-19.45313a76.88944,76.88944,0,0,1,11.1925.97656V32.8125h-6.305c-6.21112,0-8.14813,3.85419-8.14813,7.80835V50H71.67969l-2.2168,14.45312H57.8125V99.39233A50.00348,50.00348,0,0,0,50,0Z" />
  </svg>
)
