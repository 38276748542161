import React from "react"
import { graphql } from "gatsby"
import { rr } from "../lib/reverse-router"
import { formatDate } from "../lib/string"
import Heading from "./Heading"
import Link from "./Link"
import PostContent from "./PostContent"
import { IconFacebook, IconProfile, IconTwitter } from "./Icons"
const md = require("markdown-it")()
import Helmet from "react-helmet"
import WeakText from "./WeakText"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./PostEssentials.module.scss"
import RelatedPost from "./RelatedPost"

export const PostEssentialsFragment = graphql`
  fragment PostEssentialsFragment on ContentfulPost {
    title
    category
    createdAt
    createdAtOriginal
    updatedAt
    thumbnail {
      gatsbyImageData(placeholder: DOMINANT_COLOR)
      description
    }
    lead {
      childMarkdownRemark {
        html
      }
    }
    content {
      childMarkdownRemark {
        html
      }
    }
  }
`

export const NewsEssentialsFragment = graphql`
  fragment NewsEssentialsFragment on ContentfulNews {
    title
    category
    createdAt
    createdAtOriginal
    updatedAt
    thumbnail {
      gatsbyImageData(placeholder: DOMINANT_COLOR)
      description
    }
    content {
      childMarkdownRemark {
        html
      }
    }
  }
`

const Author: React.FC = () => {
  return (
    <section className={styles.author}>
      <Heading level={2} appearance={6}>
        この記事を書いた人
      </Heading>
      <div className={styles.content}>
        <div className={styles.icon}>
          <IconProfile />
        </div>
        <div className={styles.profile}>
          村田 智<WeakText>デザイナー</WeakText>
          <Link to={rr.about.default()}>詳しくみる→</Link>
        </div>
      </div>
    </section>
  )
}

interface ShareButtonsProps {
  title: string
  uri: string
}

const ShareButtons: React.FC<ShareButtonsProps> = ({ title, uri }) => {
  const shareUrl = `https://satoshimurata.com${uri}`
  return (
    <section className={styles.shareButtons}>
      <ul>
        <li>
          <TwitterShareButton
            title={title}
            url={shareUrl}
            resetButtonStyle={false}
          >
            <IconTwitter />
            Twitter
          </TwitterShareButton>
        </li>
        <li>
          <FacebookShareButton
            quote={title}
            url={shareUrl}
            resetButtonStyle={false}
          >
            <IconFacebook />
            Facebook
          </FacebookShareButton>
        </li>
      </ul>
    </section>
  )
}

interface PostEssentialsProps {
  post: any
  relatedPosts: any
  uri: string
}

const PostEssentials: React.FC<PostEssentialsProps> = ({
  post,
  relatedPosts,
  uri,
}) => {
  const {
    thumbnail,
    title,
    createdAt,
    createdAtOriginal,
    updatedAt,
    lead,
    content,
  } = post

  return (
    <>
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "BlogPosting",
              headline: title,
              author: "Satoshi Murata",
              datePublished: createdAtOriginal || createdAt,
              dateModified: updatedAt,
            }),
          },
        ]}
      />
      <header className={styles.header}>
        <Heading level={1}>{title}</Heading>
      </header>
      {thumbnail && (
        <figure className={styles.figure}>
          <GatsbyImage image={thumbnail.gatsbyImageData} alt="" />
          {thumbnail.description && (
            <figcaption
              dangerouslySetInnerHTML={{
                __html: md.render(thumbnail.description),
              }}
            />
          )}
        </figure>
      )}
      {lead && (
        <div className={styles.lead}>
          <PostContent body={lead.childMarkdownRemark.html} />
        </div>
      )}
      {content && (
        <div className={styles.main}>
          <PostContent body={content.childMarkdownRemark.html} />
        </div>
      )}
      <RelatedPost relatedPosts={relatedPosts} />
      <ShareButtons title={title} uri={uri} />
    </>
  )
}

export default PostEssentials
