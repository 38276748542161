import React from "react"
import { graphql, Link } from "gatsby"
import {
  absoluteUrl,
  formatDateTime,
  stripTagsAndNewLines,
  truncateText,
} from "../lib/string"
import Layout from "../components/Layout"
import ConstrainWidth from "../components/ConstrainWidth"
import PostEssentials from "../components/PostEssentials"
import { rr } from "../lib/reverse-router"
import * as styles from "./post.module.scss"
import UnderlineLink from "../components/UnderlineLink"
import { IconChevron } from "../components/Icons"

export const query = graphql`
  query ($slug: String!, $category: String!) {
    contentfulPost(slug: { eq: $slug }) {
      ...PostEssentialsFragment
      slug
      tags
    }
    relatedPosts: allContentfulPost(filter: { category: { eq: $category } }) {
      edges {
        node {
          id
          slug
          title
          tags
        }
      }
    }
  }
`

const Page = ({ data, uri }) => {
  const {
    title,
    category,
    createdAt,
    updatedAt,
    createdAtOriginal,
    lead,
    content,
    tags,
  } = data.contentfulPost

  const description = lead
    ? truncateText(stripTagsAndNewLines(lead.childMarkdownRemark.html), 300)
    : undefined

  const noBreakLead = lead
    ? stripTagsAndNewLines(lead.childMarkdownRemark.html)
    : ""

  const noBreakContent = content
    ? stripTagsAndNewLines(content.childMarkdownRemark.html)
    : ""

  const bodyLength = [...noBreakLead, ...noBreakContent].length

  return (
    <Layout title={title} uri={uri} description={description}>
      <ConstrainWidth>
        <article className={styles.article}>
          <PostEssentials
            post={data.contentfulPost}
            relatedPosts={data.relatedPosts}
            uri={uri}
          />
          <footer className={styles.footer}>
            <UnderlineLink>
              <dl>
                {category && (
                  <div>
                    <dt>カテゴリー</dt>
                    <dd>
                      <Link to={rr.post.category(category)}>{category}</Link>
                    </dd>
                  </div>
                )}
                {tags && (
                  <div>
                    <dt>タグ</dt>
                    <dd>
                      <ul>
                        {tags.map((tag, i) => (
                          <li key={i}>
                            <Link to={rr.post.tag(tag)}>{tag}</Link>
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </div>
                )}
                <div className={styles.createdAt}>
                  <dt>公開日</dt>
                  <dd>
                    <time dateTime={createdAtOriginal || createdAt}>
                      {formatDateTime(createdAtOriginal || createdAt)}
                    </time>
                  </dd>
                </div>
                <div>
                  <dt>更新日</dt>
                  <dd>
                    <time dateTime={updatedAt}>
                      {formatDateTime(updatedAt)}
                    </time>
                  </dd>
                </div>
                <div>
                  <dt>文字数</dt>
                  <dd>{bodyLength.toLocaleString()}文字</dd>
                </div>
              </dl>
            </UnderlineLink>
          </footer>
        </article>
        <ul className={styles.breadcrumbs}>
          <li>
            <Link to={rr.post.default()}>記事一覧</Link>
            <IconChevron />
          </li>
          <li>
            <Link to={rr.post.category(category)}>{category}</Link>
          </li>
        </ul>
      </ConstrainWidth>
    </Layout>
  )
}

export default Page

export const Head = ({ data }) => {
  const { category } = data.contentfulPost
  return (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "記事一覧",
            item: absoluteUrl(rr.post.default()),
          },
          {
            "@type": "ListItem",
            position: 2,
            name: category,
            item: absoluteUrl(rr.post.category(category)),
          },
        ],
      })}
    </script>
  )
}
